<template>
<div>

    <v-card flat style="background-color: var(--v-background-base) !important">
        <v-toolbar color="greyBase" dark extended flat extension-height="55">
            <v-toolbar-title>
                <b class="mr-3">TUM V3 Infrastructure</b>
            </v-toolbar-title>
        </v-toolbar>

        <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
                'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
                'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
            }">
            <v-card-text :style="{
                height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                'overflow-y': 'auto',
            }">
                <div>
                    <v-tabs grow v-model="tab">
                        <v-tab key="dashboard">Dashboard</v-tab>
                        <!-- <v-tab key="data">TUM V3 Data</v-tab> -->
                        <v-tab key="transaction">Transaction Logs</v-tab>

                        <v-tab-item key="dashboard">
                            <v-container>
                                <v-row>
                                    <v-col cols="6">
                                        <v-card>
                                            <v-toolbar flat dark>
                                                <v-toolbar-title>TUM V3 API Errors</v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="getMetricInformation('Errors')" icon>
                                                    <v-icon>info</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <DynamicSmoothLineChart :key="refreshKey" :color="'green'" defaultChart="line" xAxisLabel="Timestamps" yAxisLabel="Count" :textColor="'grey'" :data="errorChart.data" :labels="errorChart.chartOptions.xaxis.categories"></DynamicSmoothLineChart>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-card>
                                            <v-toolbar flat dark>
                                                <v-toolbar-title>TUM V3 API Invocations</v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="getMetricInformation('Invocations')" icon>
                                                    <v-icon>info</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <DynamicSmoothLineChart :key="refreshKey" :color="'green'" defaultChart="line" xAxisLabel="Timestamps" yAxisLabel="Count" :textColor="'grey'" :data="invocationsChart.data" :labels="invocationsChart.chartOptions.xaxis.categories"></DynamicSmoothLineChart>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-card>
                                            <v-toolbar flat dark>
                                                <v-toolbar-title>TUM V3 API Duration</v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="getMetricInformation('Duration')" icon>
                                                    <v-icon>info</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <DynamicSmoothLineChart :key="refreshKey" :color="'green'" defaultChart="line" xAxisLabel="Timestamps" yAxisLabel="Milliseconds" :textColor="'grey'" :data="durationChart.data" :labels="durationChart.chartOptions.xaxis.categories"></DynamicSmoothLineChart>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-card>
                                            <v-toolbar flat dark>
                                                <v-toolbar-title>TUM V3 API Throttles</v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="getMetricInformation('Throttles')" icon>
                                                    <v-icon>info</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <DynamicSmoothLineChart :key="refreshKey" :color="'green'" defaultChart="line" xAxisLabel="Timestamps" yAxisLabel="Count" :textColor="'grey'" :data="throttlesChart.data" :labels="throttlesChart.chartOptions.xaxis.categories"></DynamicSmoothLineChart>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>

                        <!-- <v-tab-item key="data">
                            <v-card flat>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" md="12" sm="12">
                                            <v-card flat>
                                                <v-card-text>
                                                    <v-row justify="end" class="mb-2">
                                                        <v-col cols="12" sm="4" md="4" lg="4">
                                                            <el-input id="dataSearch" v-model="dataSearch" suffix-icon="el-icon-search" clearable placeholder="Search">
                                                                <template slot="append">
                                                                    <el-button>
                                                                        <v-icon small>filter_alt</v-icon>
                                                                    </el-button>
                                                                </template>
                                                            </el-input>
                                                        </v-col>
                                                    </v-row>
                                                    <v-data-table :loading="dataLoading" dense :key="refreshKey" :items="computedData" hide-default-footer :headers="dataHeaders" :options="{
                                                page: dataParams.page,
                                                itemsPerPage: dataParams.limit,
                                                pageStart: 1,
                                            }">

                                                        <template v-slot:item.DateTime="{ item }">
                                                            {{ new
                Date(item.DateTime).toLocaleString('en-ZA', {
                    timeZone: 'Africa/Johannesburg',
                    dateStyle: 'medium',
                    timeStyle: 'medium'
                }) }}
                                                        </template>
                                                    </v-data-table>
                                                    <v-row justify="center" class="mb-2">
                                                        <v-col cols="12" sm="12" md="12" lg="12">
                                                            <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="dataParams.page" :page-size.sync="dataParams.limit" :page-sizes="[5, 10, 15]" layout="sizes, prev, pager, next, jumper, total" :total="dataTotal" class="text-center"></el-pagination>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-tab-item> -->

                        <v-tab-item key="transaction">
                            <v-card>
                                <v-card-text>
                                    <v-row justify="end" class="mb-2">
                                        <v-col cols="12" sm="4" md="4" lg="4">
                                            <el-input id="transactionLogSearch" suffix-icon="el-icon-search" v-model="transactionLogSearch" clearable placeholder="Search">
                                                <template slot="append">
                                                    <el-button @click="filterDialog = !filterDialog" el-tooltip="Advanced Filter">
                                                        <v-icon small>filter_alt</v-icon>
                                                    </el-button>
                                                </template>
                                            </el-input>
                                        </v-col>
                                    </v-row>
                                    <v-data-table :loading="transactionLogsLoading" dense :key="updateKey" :items="computedTransactionLogs" hide-default-footer :headers="transactionLogsHeaders" :options="{
                page: transactionLogsParams.page,
                itemsPerPage: transactionLogsParams.limit,
                pageStart: 1,
            }" @click:row="readLog">
                                        <template v-slot:item.lastModified="{ item }">
                                            {{ new
                Date(item.lastModified).toLocaleString('en-ZA', {
                    timeZone: 'Africa/Johannesburg',
                    dateStyle: 'medium',
                    timeStyle: 'medium'
                }) }}
                                        </template>
                                    </v-data-table>
                                    <v-row justify="center" class="mb-2">
                                        <v-col cols="12" sm="12" md="12" lg="12">
                                            <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="transactionLogsParams.page" :page-size.sync="transactionLogsParams.limit" :page-sizes="[5, 10, 15]" layout="sizes, prev, pager, next, jumper, total" :total="transactionLogsTotal" class="text-center"></el-pagination>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </div>

            </v-card-text>
        </v-card>
    </v-card>

    <v-dialog v-model="filterDialog" persistent max-width="600px">
        <v-card dark>
            <v-toolbar>
                <v-card-title>
                    <span class="text-h5">Advanced filter</span>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" plain depressed elevation="0" :disabled="!filterActive" @click="clearFilterDialog">
                            <v-icon>filter_alt_off</v-icon>
                        </v-btn>
                    </template>
                    <span>Clear Filters</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" plain depressed elevation="0" @click="filterSearch">
                            <v-icon>filter_alt</v-icon>
                        </v-btn>
                    </template>
                    <span>Apply Filters</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" plain depressed elevation="0" @click="closeFilterDialog()">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </template>
                    <span>Close</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text>

            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog persistent v-model="readLogDialog" max-width="1000">
        <v-card flat height="800" style="overflow-y: hidden">
            <v-toolbar flat dark>
                <v-toolbar-title>Read Log</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="readLogDialog = !readLogDialog">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <vue-json-pretty height="725" :data="logJSON" :showLine="false" :virtual="true" :showIcon="true" />
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog persistent v-model="infoDialog" max-width="600">
        <v-card flat style="overflow-y: hidden">
            <v-toolbar flat dark>
                <v-toolbar-title>Metric Information - {{ this.infoType }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="infoDialog = !infoDialog">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                {{ this.infoText }}
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import DynamicSmoothLineChart from "../components/Charts/TUMChart.vue";
import dateFormat from "dateformat";
export default {
    components: {
        DynamicSmoothLineChart,
    },
    data: () => ({
        infoDialog: false,
        infoType: "",
        infoText: "",
        refreshKey: 0,
        dataOptions: ["Sum", "Average", "Maximum", "Minimum", "SampleCount"],
        errorChart: {
            data: [{
                data: [],
                color: 'green',
                type: "line",
                smooth: true,
                showInLegend: true,
            }, ],
            chartOptions: {
                chart: {
                    id: 'stock-chart',
                    toolbar: {
                        show: false, // Hide the toolbar
                    },
                },
                xaxis: {
                    categories: [],
                },
                title: {
                    text: 'Change in Stock - May 2023',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: 'white', // change the color to white
                    },
                },
                tooltip: {
                    enabled: false,
                    enabledOnSeries: undefined,
                    shared: true,
                    followCursor: true,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    theme: false,
                    style: {
                        fontSize: '12px',
                        fontFamily: undefined
                    },
                    onDatasetHover: {
                        highlightDataSeries: false,
                    },
                    x: {
                        show: true,
                        format: 'dd MMM',
                        formatter: undefined,
                    },
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                    z: {
                        formatter: undefined,
                        title: 'Size: '
                    },
                    marker: {
                        show: true,
                    },
                    fixed: {
                        enabled: false,
                        position: 'topRight',
                        offsetX: 0,
                        offsetY: 0,
                    },
                }
            },
        },
        invocationsChart: {
            data: [{
                data: [],
                color: 'green',
                type: "line",
                smooth: true,
                showInLegend: true,
            }, ],
            chartOptions: {
                chart: {
                    id: 'stock-chart',
                    toolbar: {
                        show: false, // Hide the toolbar
                    },
                },
                xaxis: {
                    categories: [],
                },
                title: {
                    text: 'Change in Stock - May 2023',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: 'white', // change the color to white
                    },
                },
                tooltip: {
                    enabled: false,
                    enabledOnSeries: undefined,
                    shared: true,
                    followCursor: true,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    theme: false,
                    style: {
                        fontSize: '12px',
                        fontFamily: undefined
                    },
                    onDatasetHover: {
                        highlightDataSeries: false,
                    },
                    x: {
                        show: true,
                        format: 'dd MMM',
                        formatter: undefined,
                    },
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                    z: {
                        formatter: undefined,
                        title: 'Size: '
                    },
                    marker: {
                        show: true,
                    },
                    fixed: {
                        enabled: false,
                        position: 'topRight',
                        offsetX: 0,
                        offsetY: 0,
                    },
                }
            },
        },
        durationChart: {
            data: [{
                data: [],
                color: 'green',
                type: "line",
                smooth: true,
                showInLegend: true,
            }, ],
            chartOptions: {
                chart: {
                    id: 'stock-chart',
                    toolbar: {
                        show: false, // Hide the toolbar
                    },
                },
                xaxis: {
                    categories: [],
                },
                title: {
                    text: 'Change in Stock - May 2023',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: 'white', // change the color to white
                    },
                },
                tooltip: {
                    enabled: false,
                    enabledOnSeries: undefined,
                    shared: true,
                    followCursor: true,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    theme: false,
                    style: {
                        fontSize: '12px',
                        fontFamily: undefined
                    },
                    onDatasetHover: {
                        highlightDataSeries: false,
                    },
                    x: {
                        show: true,
                        format: 'dd MMM',
                        formatter: undefined,
                    },
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                    z: {
                        formatter: undefined,
                        title: 'Size: '
                    },
                    marker: {
                        show: true,
                    },
                    fixed: {
                        enabled: false,
                        position: 'topRight',
                        offsetX: 0,
                        offsetY: 0,
                    },
                }
            },
        },
        throttlesChart: {
            data: [{
                data: [],
                color: 'green',
                type: "line",
                smooth: true,
                showInLegend: true,
            }, ],
            chartOptions: {
                chart: {
                    id: 'stock-chart',
                    toolbar: {
                        show: false, // Hide the toolbar
                    },
                },
                xaxis: {
                    categories: [],
                },
                title: {
                    text: 'Change in Stock - May 2023',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: 'white', // change the color to white
                    },
                },
                tooltip: {
                    enabled: false,
                    enabledOnSeries: undefined,
                    shared: true,
                    followCursor: true,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    theme: false,
                    style: {
                        fontSize: '12px',
                        fontFamily: undefined
                    },
                    onDatasetHover: {
                        highlightDataSeries: false,
                    },
                    x: {
                        show: true,
                        format: 'dd MMM',
                        formatter: undefined,
                    },
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                    z: {
                        formatter: undefined,
                        title: 'Size: '
                    },
                    marker: {
                        show: true,
                    },
                    fixed: {
                        enabled: false,
                        position: 'topRight',
                        offsetX: 0,
                        offsetY: 0,
                    },
                }
            },
        },
        logJSON: {},
        readLogDialog: false,
        filters: {},
        filterDialog: false,
        filterActive: false,
        updateKey: 0,
        transactionLogs: [],
        transactionLogSearch: "",
        transactionLogsTotal: 0,
        transactionLogsLoading: false,
        transactionLogsHeaders: [{
                text: "File Name",
                value: "fileName"
            },
            {
                text: "File Type",
                value: "fileType"
            },
            {
                text: "File Size",
                value: "size"
            },
            {
                text: "Upload Date",
                value: "lastModified",
            }
        ],
        transactionLogsParams: {
            limit: 15,
            page: 1
        },
        data: [],
        dataSearch: "",
        dataTotal: 0,
        dataLoading: false,
        dataHeaders: [],
        dataParams: {
            limit: 15,
            page: 1
        }
    }),
    created() {
        this.getTransactionLogs()
        this.getErrorMetrics()
        this.getInvocationsMetrics()
        this.getDurationMetrics()
        this.getThrottles()
        // this.getData()
    },
    watch: {
        'transactionLogsParams.limit': {
            handler: async function () {
                this.transactionLogsParams.offset = (this.transactionLogsParams.page - 1) * this.transactionLogsParams.limit;
                // console.log({
                //     page: this.articlePage
                // })
                await this.getTransactionLogs();
                this.updateKey++;
            },
            immediate: true
        },
        'transactionLogsParams.page': {
            handler: async function () {
                this.transactionLogsParams.offset = (this.transactionLogsParams.page - 1) * this.transactionLogsParams.limit;
                // console.log({
                //     page: this.articlePage
                // })
                await this.getTransactionLogs();
                this.updateKey++;
            },
            immediate: true
        },
        transactionLogSearch: {
            handler: function () {
                setTimeout(async () => {
                    await this.getTransactionLogs()
                }, 2000);
            },
            immediate: true
        },
    },
    methods: {
        async getMetricInformation(type) {
            this.infoDialog = true

            switch (type) {
                case "Errors":
                    this.infoType = "Errors"
                    this.infoText = "The number of invocations that result in a function error. Function errors include exceptions that your code throws and exceptions that the Lambda runtime throws. The runtime returns errors for issues such as timeouts and configuration errors. Note that the timestamp on an error metric reflects when the function was invoked, not when the error occurred."
                    break;
                case "Invocations":
                    this.infoType = "Invocations"
                    this.infoText = "The number of times that your function code is invoked, including successful invocations and invocations that result in a function error. 'Invocations' aren't recorded if the invocation request is throttled or otherwise results in an invocation error. The value of equals the number of requests billed."
                    break;
                case "Duration":
                    this.infoType = "Duration"
                    this.infoText = "The amount of time that your function code spends processing an event. The billed duration for an invocation is the value of 'Duration' rounded up to the nearest millisecond. 'Duration' does not include cold start time."
                    break;
                case "Throttles":
                    this.infoType = "Throttles"
                    this.infoText = "The number of invocation requests that are throttled. When all function instances are processing requests and no concurrency is available to scale up, Lambda rejects additional requests with a 'TooManyRequestsException' error. Throttled requests and other invocation errors don't count as either 'Invocations' or 'Errors'."
                    break;
                default:
                    console.error("Unknown Metric Type");
            }
        },
        async getData() {
            let response = await this.$API.getTUMData({
                params: {
                    dataType: "tradeUnit"
                }
            })

            this.data = response.responseData;

            console.log({
                responseData: this.data
            })

            delete this.data.TradeUnit

            const objectKeys = Object.keys(this.data[0]).filter(item => item !== "TradeUnit");

            console.log({
                objectKeys
            })

            this.dataHeaders = objectKeys.map(key => ({
                text: key.charAt(0).toUpperCase() + key.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2'), // Convert camelCase to Title Case
                value: key
            }));

            console.log({
                dataHeaders: this.dataHeaders
            });

            this.dataTotal = response.total;
        },
        async getErrorMetrics() {
            let response = await this.$API.getMetrics({
                params: {
                    metricName: "Errors"
                }
            })

            let datapoints = response[0].Datapoints

            this.errorChart.data[0].data = datapoints.map((item) => {
                return item.Sum
            })
            this.errorChart.chartOptions.xaxis.categories = datapoints.map((item) => {
                return item.Timestamp
            })

            this.refreshKey++
        },
        async getInvocationsMetrics() {
            let response = await this.$API.getMetrics({
                params: {
                    metricName: "Invocations"
                }
            })

            let datapoints = response[0].Datapoints

            this.invocationsChart.data[0].data = datapoints.map((item) => {
                return item.Sum
            })
            this.invocationsChart.chartOptions.xaxis.categories = datapoints.map((item) => {
                return item.Timestamp
            })

            this.refreshKey++
        },
        async getDurationMetrics() {
            let response = await this.$API.getMetrics({
                params: {
                    metricName: "Duration"
                }
            })

            let datapoints = response[0].Datapoints

            this.durationChart.data[0].data = datapoints.map((item) => {
                return item.Sum
            })
            this.durationChart.chartOptions.xaxis.categories = datapoints.map((item) => {
                return item.Timestamp
            })

            this.refreshKey++
        },
        async getThrottles() {
            let response = await this.$API.getMetrics({
                params: {
                    metricName: "Throttles"
                }
            })

            let datapoints = response[0].Datapoints

            this.throttlesChart.data[0].data = datapoints.map((item) => {
                return item.Sum
            })
            this.throttlesChart.chartOptions.xaxis.categories = datapoints.map((item) => {
                return item.Timestamp
            })

            this.refreshKey++
        },
        async readLog(item) {
            console.log(item);

            this.logJSON = await this.$API.readTransactionLog({
                ...item,
                stage: "production"
            })

            this.readLogDialog = true;
        },
        async getTransactionLogs() {
            let resp = await this.$API.getTransactionLogs({
                params: {
                    search: this.transactionLogSearch,
                    ...this.transactionLogsParams,
                    stage: "production",
                },
            });

            this.transactionLogs = resp.paginatedFileList

            this.transactionLogsTotal = resp.total

            console.log(this.transactionLogs)
        },
        async filterSearch() {
            if (this.filters) {
                this.transactionLogsParams.filters = this.filters
            }

            this.allArticles();
            this.closeFilterDialog();
            this.filterActive = true
        },
        async clearFilterDialog() {
            this.filters = {}
            this.filterActive = false
            delete this.transactionLogsParams.filters
        },
        async closeFilterDialog() {
            this.filterDialog = false
        },
    },
    computed: {
        computedTransactionLogs() {
            let result = this.transactionLogs;
            return result
        },
        computedData() {
            let result = this.data;
            return result
        }
    }
}
</script>
